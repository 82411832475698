import {
  Component, ElementRef, ViewEncapsulation, HostListener, AfterViewInit, OnInit, OnDestroy
} from '@angular/core';
import { TreeVirtualScroll } from '../models/tree-virtual-scroll.model';
import { deprecatedSelector } from '../deprecated-selector';
import { TREE_EVENTS } from '../constants/events';


export class TreeViewportComponent implements AfterViewInit, OnInit, OnDestroy {
  constructor(
    private elementRef: ElementRef,
    public virtualScroll: TreeVirtualScroll) {

    deprecatedSelector('TreeNode', 'tree-node', elementRef);
  }

  ngOnInit() {
    this.virtualScroll.init();
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setViewport();
      this.virtualScroll.fireEvent({ eventName: TREE_EVENTS.onInitialized });
    });
  }

  ngOnDestroy() {
    this.virtualScroll.clear();
  }

  
  onScroll(e) {
    this._onWheel(e);
  }

  getTotalHeight() {
    return this.virtualScroll.isEnabled() && this.virtualScroll.totalHeight + 'px' || 'auto';
  }

  _onWheel(e) {
    this.setViewport();
  }

  setViewport() {
    this.virtualScroll.setViewport(this.elementRef.nativeElement);
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  selector: 'TreeViewport, tree-viewport',
  styles: [
    `:host {
      height: 100%;
      overflow: auto;
      display: block;
    }`
  ],
  providers: [TreeVirtualScroll],
  template: `
    <ng-container *mobxAutorun>
      <div [style.height]="getTotalHeight()">
        <ng-content></ng-content>
      </div>
    </ng-container>
  `
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: TreeVirtualScroll, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'onScroll': [{ type: HostListener, args: ['scroll', ['$event'], ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
