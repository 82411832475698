import { Injectable } from '@angular/core';


export class TreeDraggedElement {
  _draggedElement: any = null;

  set(draggedElement: any) {
    this._draggedElement = draggedElement;
  }

  get(): any {
    return this._draggedElement;
  }

  isDragging() {
    return !!this.get();
  }
static decorators: DecoratorInvocation[] = [
{ type: Injectable },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
