import { Component, Input, TemplateRef, ElementRef, ViewEncapsulation } from '@angular/core';
import { TreeNode } from '../models/tree-node.model';
import { deprecatedSelector } from '../deprecated-selector';


export class LoadingComponent {
   template: TemplateRef<any>;
   node: TreeNode;

  constructor(private elementRef: ElementRef) {
    deprecatedSelector('LoadingComponent', 'tree-loading-component', elementRef);
  }
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
  encapsulation: ViewEncapsulation.None,
  selector: 'LoadingComponent, tree-loading-component',
  template: `
    <span *ngIf="!template">loading...</span>
    <ng-container
      [ngTemplateOutlet]="template"
      [ngOutletContext]="{ $implicit: node }">
    </ng-container>
  `,
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'template': [{ type: Input },],
'node': [{ type: Input },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
