import { Directive, Output, Input, EventEmitter, HostListener, Renderer, ElementRef } from '@angular/core';
import { TreeDraggedElement } from '../models/tree-dragged-element.model';

const DRAG_OVER_CLASS = 'is-dragging-over';
const DRAG_DISABLED_CLASS = 'is-dragging-over-disabled';


export class TreeDropDirective {
   onDropCallback = new EventEmitter();
   onDragOverCallback = new EventEmitter();
   onDragLeaveCallback = new EventEmitter();
   onDragEnterCallback = new EventEmitter();

  private _allowDrop = (element, $event) => true;
   set treeAllowDrop(allowDrop) {
    if (allowDrop instanceof Function) {
      this._allowDrop = allowDrop;
    }
    else this._allowDrop = (element, $event) => allowDrop;
  }
  allowDrop($event) {
    return this._allowDrop(this.treeDraggedElement.get(), $event);
  }

  constructor(private el: ElementRef, private renderer: Renderer, private treeDraggedElement: TreeDraggedElement) {
  }

   onDragOver($event) {
    if (!this.allowDrop($event)) return this.addDisabledClass();

    this.onDragOverCallback.emit({event: $event, element: this.treeDraggedElement.get()});

    $event.preventDefault();
    this.addClass();
  }

   onDragEnter($event) {
    if (!this.allowDrop($event)) return;

    this.onDragEnterCallback.emit({event: $event, element: this.treeDraggedElement.get()});
  }

   onDragLeave($event) {
    if (!this.allowDrop($event)) return this.removeDisabledClass();

    this.onDragLeaveCallback.emit({event: $event, element: this.treeDraggedElement.get()});

    this.removeClass();
  }

   onDrop($event) {
    if (!this.allowDrop($event)) return;

    $event.preventDefault();
    this.onDropCallback.emit({event: $event, element: this.treeDraggedElement.get()});
    this.removeClass();
    this.treeDraggedElement.set(null);
  }

  private addClass() {
    this.renderer.setElementClass(this.el.nativeElement, DRAG_OVER_CLASS, true);
  }

  private removeClass() {
    this.renderer.setElementClass(this.el.nativeElement, DRAG_OVER_CLASS, false);
  }

  private addDisabledClass() {
    this.renderer.setElementClass(this.el.nativeElement, DRAG_DISABLED_CLASS, true);
  }

  private removeDisabledClass() {
    this.renderer.setElementClass(this.el.nativeElement, DRAG_DISABLED_CLASS, false);
  }
static decorators: DecoratorInvocation[] = [
{ type: Directive, args: [{
  selector: '[treeDrop]'
}, ] },
];
/** @nocollapse */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer, },
{type: TreeDraggedElement, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'onDropCallback': [{ type: Output, args: ['treeDrop', ] },],
'onDragOverCallback': [{ type: Output, args: ['treeDropDragOver', ] },],
'onDragLeaveCallback': [{ type: Output, args: ['treeDropDragLeave', ] },],
'onDragEnterCallback': [{ type: Output, args: ['treeDropDragEnter', ] },],
'treeAllowDrop': [{ type: Input },],
'onDragOver': [{ type: HostListener, args: ['dragover', ['$event'], ] },],
'onDragEnter': [{ type: HostListener, args: ['dragenter', ['$event'], ] },],
'onDragLeave': [{ type: HostListener, args: ['dragleave', ['$event'], ] },],
'onDrop': [{ type: HostListener, args: ['drop', ['$event'], ] },],
};
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
